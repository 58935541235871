<template>
  <div class="feedback page">
    <form
      action="https://formspree.io/FORM_ID"
      method="POST"
    >
    <h1 class="text-lg-center text-title-1">Submit Your Feedback</h1>
    <p class="subtitle text-gray text-lg-center mb-lg-4">We love hearing your feedback.<br class="d-none d-lg-block"> Please let us know what you think!</p>
    <hr class="w-100 mx-0 mb-lg-5">
    <b-form-group label="Email Address:" label-for="input-email" class="mx-lg-auto">
      <b-form-input id="input-email" type="email" name="_replyto" required placeholder="Enter Email"></b-form-input>
    </b-form-group>

    <b-form-group label="Feedback:" label-for="feedback" class="mx-lg-auto">
      <b-form-textarea id="feedback" name="feedback" placeholder="Enter something..." rows="8" max-rows="10">
      </b-form-textarea>
    </b-form-group>

    <button type="submit" value="Send" class="btn btn-primary mx-lg-auto d-lg-block">Send</button>
    </form>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">

  .page.feedback {
    background-color: #F0F0F0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;

    hr {
      max-width: none;
      height: 2px;
    }


    form {
      max-width: 500px;
      margin: auto;
      margin-top: 20px;
      width: 90%;
      height: 100%;
      padding: 40px 20px;
      background-color: #FFF;
      flex: 1 1 auto;
    }

    @media only screen and (min-width: 992px) {
      form {
        margin-top: 0;
        max-width: 700px;
        padding: 80px 60px;

        h1 {
          font-size: 40px;
        }

        p {
          font-size: 18px;
          font-weight: 500;
          margin-top: 15px;
        }

        .form-group {
          max-width: 450px;
        }
      }
    }
  }

</style>

